/*
 * This file contains imports of all packages that are common
 * for each frontend service.
 */
import SystemJS from 'systemjs';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-dates/initialize';

SystemJS.config(window.sofeManifest);

// register sofe
registerDep('sofe', () => require('sofe'));

registerDep('react', () => require('react'));
registerDep('react-dom', () => require('react-dom'));
registerDep('react-dom/server', () => require('react-dom/server'));
registerDep('react-router-dom', () => require('react-router-dom'));
registerDep('wss-components', () => require('wss-components'));

registerDep('single-spa', () => require('single-spa'));
registerDep('single-spa-react', () => require('single-spa-react'));
registerDep('styled-components', () => require('styled-components'));
registerDep('react-dates', () => require('react-dates'));
registerDep('react-select', () => require('react-select'));
registerDep('react-final-form', () => require('react-final-form'));
registerDep('final-form', () => require('final-form'));
registerDep('@wfp/ui', () => require('@wfp/ui'));
registerDep('@wfp/icons', () => require('@wfp/icons'));
registerDep('axios', () => require('axios'));
registerDep('@sentry/browser', () => require('@sentry/browser'));
registerDep('dayjs', () => require('dayjs'));
registerDep('moment', () => require('moment'));
registerDep('react-loading-overlay', () => require('react-loading-overlay'));
registerDep('react-truncate-html', () => require('react-truncate-html'));
registerDep('react-select/lib/Async', () => require('react-select/lib/Async'));

// Package for notifications
registerDep('react-toastify', () => require('react-toastify'));

// A "requirer" is a function that requires a module. It is not called until
// a sofe service needs the dependency. This prevents the code from being executed
// unnecessarily during the critical initialization phase of the app
function registerDep(name, requirer) {
  SystemJS.registerDynamic(name, [], false, function(_r, _e, _m) {
    _m.exports = requirer();
  });
}
